import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const CaTransparencyStatement = ({ pageContext, location }) => {
  const pageTitle = "CA Transparency Statement"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h2>
          Rocky Brands’ California Transparency in Supply Chains Act Statement
        </h2>
        <p>
          Rocky Brands, Inc. maintains high standards of business ethics and
          holds a deep regard for human rights. We recognize the importance of
          responsibly sourced products and are committed to working with supply
          chain business partners that are dedicated to safe, humane, and
          ethical working conditions that comply with international labor
          standards. We have owned and operated our own factories in Puerto Rico
          and the Dominican Republic for over 30 years, each of which regularly
          employs from 700 to 1,000 employees, so we know how to operate large
          footwear factories in an ethical and lawful manner. The knowledge we
          gain from operating our own factories assists us in seeking out and
          contracting with business partners who have the same ethics and regard
          for their employees.
        </p>
        <p>
          Pursuant to the requirements of the California Transparency in Supply
          Chains Act, Rocky Brands makes the following disclosures of our
          efforts to eradicate slavery and human trafficking from our supply
          chain:
        </p>
        <ul>
          <li>
            <p>
              <strong>Verification</strong>
            </p>
            <p>
              Rocky Brands conducts its own verification for our finished goods
              factories to assess risks related to human trafficking, slavery
              and other social compliance metrics.
            </p>
          </li>
          <li>
            <p>
              <strong>Auditing</strong>
            </p>
            <p>
              Rocky Brands has not previously engaged an independent party to
              conduct announced audits of direct suppliers’ facilities but
              retains the right to inspect or audit its suppliers’ facilities.
            </p>
          </li>
          <li>
            <p>
              <strong>Certification</strong>
            </p>
            <p>
              Rocky Brands requires direct suppliers to abide by our Ethical
              Manufacturing Principles, which requires, among other things, that
              our partners commit to comply with all laws and regulations in all
              locations where they conduct business; uphold the human rights of
              workers, including not utilizing involuntary or forced labor,
              whether indentured, bonded or otherwise, or child labor; and
              provide a work environment free of harassment, abuse or corporal
              punishment of any form. Direct suppliers are required to certify
              such compliance in contracts with Rocky Brands and otherwise at
              Rocky Brands’ request.
            </p>
          </li>
          <li>
            <p>
              <strong>Internal Accountability</strong>
            </p>
            <p>
              Rocky Brands maintains internal accountability procedures to
              ensure compliance with the Company’s standards on ethical
              manufacturing principles, slavery and human trafficking.
            </p>
          </li>
          <li>
            <p>
              <strong>Training</strong>
            </p>
            <p>
              Rocky Brands trains its employees who are directly responsible for
              sourcing finished goods on mitigating the risk of slavery and
              human trafficking in our supply chain.
            </p>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default CaTransparencyStatement
